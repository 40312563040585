@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";
@import "overrides";
@import "variables";
@import "typo";

html,
body {
  font-size: 16px;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  overflow-x: hidden;

  box-sizing: border-box;
  word-break: auto-phrase;

  @apply hyphens-auto;

  &.no-scroll {
    overflow: hidden !important;
  }

  @media print {
    * {
      transition: none !important;
    }
  }
}

// Fix for radix dialog, which adds margin-right: 15px to body
// See https://github.com/radix-ui/primitives/issues/1925
body {
  margin-right: 0 !important;
}

.anchorlink {
  @apply invisible relative -top-[200px] block;
}

p:has(+ p) {
  @apply mb-4;
}
