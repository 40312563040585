.swiper-pagination-wrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  z-index: 50 !important;

  .swiper-pagination-custom {
    width: 100%;
    @screen lg {
      width: 50% !important;
      margin: 0 auto;
    }

    position: relative !important;
    left: unset !important;
    min-height: 20px !important;
    padding-top: 68px;
  }

  .swiper-pagination-bullet {
    @apply h-[4px] rounded-[2px] bg-neutral-200 opacity-100;

    &.swiper-pagination-bullet-active {
      @apply bg-toom-red;
    }

    transition: 0.1s ease-in-out;
    &:hover {
      transform: scaleY(1.2);
    }
  }
}

.swiper-pagination-outside .swiper-pagination-wrapper {
  width: 100% !important;
}
