@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("/fonts/HelveticaNeueLTStd-HvCnO.eot");
  src:
    url("/fonts/HelveticaNeueLTStd-HvCnO.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/HelveticaNeueLTStd-HvCnO.woff2") format("woff2"),
    url("/fonts/HelveticaNeueLTStd-HvCnO.woff") format("woff"),
    url("/fonts/HelveticaNeueLTStd-HvCnO.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: "Helvetica Neue";
//  src: url("/fonts/HelveticaNeue.eot");
//  src:
//    url("/fonts/HelveticaNeue.eot?#iefix") format("embedded-opentype"),
//    url("/fonts/HelveticaNeue.woff2") format("woff2"),
//    url("/fonts/HelveticaNeue.woff") format("woff"),
//    url("/fonts/HelveticaNeue.ttf") format("truetype");
//  font-weight: 100;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: "Helvetica Neue";
//  src: url("/fonts/HelveticaNeue-Bold.eot");
//  src:
//    url("/fonts/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"),
//    url("/fonts/HelveticaNeue-Bold.woff2") format("woff2"),
//    url("/fonts/HelveticaNeue-Bold.woff") format("woff"),
//    url("/fonts/HelveticaNeue-Bold.ttf") format("truetype");
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
